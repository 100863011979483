<template>
  <div>
    <ClientList
      :clientid="currentClient.client.clientId"
      :hirestatus="'nothired'"
      :userroletype="'all'"
      :currentuserrole="currentUser.role"
      :pagetitle="'Not Hired'"
      :breadcrumb="'My Business'"
    ></ClientList>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ClientList from "@/components/widgets/client/Client-User-List-Widget.vue";

export default defineComponent({
  name: "client-not-hired",
  components: { ClientList },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });

    onMounted(() => {
      setCurrentPageTitle("Not Hired");
      setCurrentPageBreadcrumbs("My Business", ["Not Hired"]);
    });

    return {
      currentUser,
      currentClient,
    };
  },
});
</script>
